import {toast,} from "react-toastify";

import React from "react";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {HiMiniXCircle} from "react-icons/hi2";
import {IoCloudUploadOutline} from "react-icons/io5";

export function showSuccessToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <AiOutlineCheckCircle className="h-6 w-6 text-green-400" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Success!</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Request completed successfully!"}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}

export function showErrorToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <HiMiniXCircle className="h-6 w-6 text-red-400" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Error!</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Encountered Issues while processing your request!"}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        })
}

export function showMediaUploadingToast(message?: string) {
    toast(
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <IoCloudUploadOutline className="h-6 w-6 text-yellow-700" aria-hidden="true"/>
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">Uploading</p>
                <p className="mt-1 text-sm text-gray-500">{message ?? "Uploading media to server..."}</p>
            </div>
        </div>, {
            className: "pointer-events-auto w-full max-w-sm !overflow-hidden !rounded-lg !bg-white !shadow-lg !ring-1 !ring-black !ring-opacity-5",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
        }
    )
}


export const ProgressBar: React.FC<{ loaded: string, total: string, percentCompleted: number }> = ({loaded, total, percentCompleted}) => {
    return (
        <div className={"flex flex-col gap-y-2"}>
            <div className={"text-lg font-bold"}>Uploading media...</div>
            <div className="w-full bg-gray-50 rounded-full h-2.5 dark:bg-gray-400">
                <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                    style={{width: `${percentCompleted}%`}}
                />
            </div>
            <div className="text-sm mt-1 text-center">{loaded}MB of {total}MB uploaded.</div>
        </div>

    );
};

export const showProgressBar = (loaded: string, total: string,percentCompleted: number, existingToastId?: string | number): string | number => {
    if (existingToastId) {
        toast.update(existingToastId, {
            render: <ProgressBar loaded={loaded} total={total} percentCompleted={percentCompleted}/>,
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            position: "bottom-right",
        });
        return existingToastId;
    }
    return toast(<ProgressBar loaded={loaded} total={total} percentCompleted={percentCompleted}/>, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        position: "bottom-right",
        delay: 300
    });
};


import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import {
    DosAndDontsMinimalRef,
    DosAndDontStore,
    NewDosAndDontsRef
} from "@views/dos-and-donts/domain/dos-and-donts.model"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchDosAndDontsList =
    createAsyncThunk<TaggedDataState<DosAndDontsMinimalRef[]>, TaggedAction>("dosAndDonts/fetchDosAndDonts",
        async (payload) => {
            const {config} = payload.parameters

            const response = await api.get<ApplicationResponse<DosAndDontsMinimalRef[]>>(`${AppConstants.api}/dosAndDonts/list?config=${config}`)

            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const uploadDosAndDonts = createAsyncThunk<any, {
    config?: string,
    feature?: string,
    payload: NewDosAndDontsRef
}>("dosAndDonts/upload",
    async (wrapper, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/dosAndDonts/new`
            const {
                config,
                payload,
                feature
            } = wrapper

            const data = new FormData()
            const keys = Object.keys(payload) as Array<keyof NewDosAndDontsRef>
            keys.forEach(key => {
                data.append(key, (payload[key] as any))
            })

            data.append("configurationId", config!)
            data.append("featureId", feature!)

            const response = await api.post<ApplicationResponse<void>>(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })

export const deleteDosAndDonts = createAsyncThunk<void, {
    configId: string,
    ddId: string
},
    { rejectValue: SerializedError }>("dosAndDonts/delete",
    async (payload, thunkAPI) => {
        try {
            const {configId, ddId} = payload

            const response = await api.delete(`${AppConstants.api}/dosAndDonts/remove?configId=${configId}&ddId=${ddId}`)

            return response.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

const dosAndDontsSlice = createSecuredSlice({
    name: "dosAndDonts",
    initialState: {} as DosAndDontStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDosAndDontsList.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchDosAndDontsList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
            .addCase(fetchDosAndDontsList.rejected, (state,
                                                     action: PayloadAction<unknown, string,
                                                         { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default dosAndDontsSlice.reducer
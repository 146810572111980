import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import convertToForm from "@utils/payload-to-form-converter"
import {NewVideoRef, TrainingsMinimalRef, TrainingStore} from "@views/trainings/domain/trainings.model"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";
import {showProgressBar} from "@utils/toast.utils";


export const fetchVideosList =
    createAsyncThunk<TaggedDataState<TrainingsMinimalRef[]>, TaggedAction>("videos/fetchVideos",
        async (payload) => {
            const {config} = payload.parameters

            const response = await api.get<ApplicationResponse<TrainingsMinimalRef[]>>(`${AppConstants.api}/videos/list?config=${config}`)

            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

// let toastId: any = undefined

export const uploadVideo = createAsyncThunk<any, {
    config?: string,
    feature?: string,
    payload: NewVideoRef
}>("videos/upload",
    async (wrapper, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/videos/new`
            const {
                config,
                payload,
                feature
            } = wrapper
            const keys = Object.keys(payload) as Array<keyof NewVideoRef>
            const formData = convertToForm(payload, keys)

            if (config) {
                formData.append("configurationId", config)
            }

            if (feature) {
                formData.append("featureId", feature)
            }
            // toastId = showProgressBar(Number(0).toFixed(2), Number(0).toFixed(2), 0);

            const response = await api.post<ApplicationResponse<void>>(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                // onUploadProgress: progressEvent => {
                //     // console.log("progressEvent...", progressEvent)
                //     if (progressEvent.total) {
                //         const percentCompleted = Math.round(
                //             (progressEvent.loaded * 100) / progressEvent.total
                //         );
                //         const loaded = (progressEvent.loaded / (1024 * 1024)).toFixed(2)
                //         const total = (progressEvent.total / (1024 * 1024)).toFixed(2)
                //         showProgressBar(loaded, total,percentCompleted, toastId);
                //     }
                // }
            })

            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })


export const deleteVideo = createAsyncThunk<void, {
    configId: string,
    videoId: string
},
    { rejectValue: SerializedError }>("videos/delete",
    async (payload, thunkAPI) => {
        try {
            const {configId, videoId} = payload

            const response = await api.delete(`${AppConstants.api}/videos/remove?configId=${configId}&videoId=${videoId}`)

            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

const videosSlice = createSecuredSlice({
    name: "videos",
    initialState: {} as TrainingStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVideosList.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchVideosList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchVideosList.rejected, (state,
                                                action: PayloadAction<unknown, string,
                                                    { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })

    }
})

export default videosSlice.reducer